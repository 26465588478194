var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_c("h3", [_vm._v("Opmerkingen")])]),
      _c(
        "v-card-text",
        { staticClass: "pb-3" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { staticClass: "pa-5 pb-0", attrs: { md12: "" } }, [
                _c(
                  "div",
                  { staticClass: "radio-tile-group flex wrap" },
                  [
                    _vm._l(_vm.item.item.comments, function(remark, key) {
                      return _c(
                        "v-card",
                        {
                          key: key,
                          staticClass: "comments pa-4 mb-2",
                          attrs: { elevation: "2" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-space-between" },
                            [
                              _c("div", { staticClass: "d-flex flex-row" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-caption mb-1 font-weight-light"
                                  },
                                  [_vm._v(_vm._s(remark.createdBy))]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-caption ml-3 mb-1 font-weight-light"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          remark.createdDateTime
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "div",
                                [
                                  remark._id
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mt-0",
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteClicked(remark)
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-delete-forever")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" " + _vm._s(remark.text) + " ")
                        ]
                      )
                    }),
                    _c("v-textarea", {
                      staticClass: "mt-3",
                      attrs: {
                        placeholder: "Schrijf hier uw opmerkingen",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.comment,
                        callback: function($$v) {
                          _vm.comment = $$v
                        },
                        expression: "comment"
                      }
                    })
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mr-2 pb-4" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.saveClicked } },
            [_vm._v("Verzenden")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }