var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _c("h3", { staticClass: "my-4" }, [
          _vm._v("Mail-log van: " + _vm._s(_vm.item.email))
        ])
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "pa-3" },
                [
                  _vm._l(_vm.item.logs.slice().reverse(), function(log, key) {
                    return _c(
                      "v-card",
                      {
                        key: key,
                        staticClass: "comments pa-4 mb-2",
                        attrs: { elevation: "2" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-caption mb-1 font-weight-light"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("formatDateLogs")(log.time))
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-caption mb-1 font-weight-light"
                              },
                              [
                                log.status === "DONE"
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v(" mdi-email-check ")]
                                    )
                                  : _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v(" mdi-email-off ")
                                    ])
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" " + _vm._s(log.template) + " ")
                      ]
                    )
                  }),
                  _vm.item.logs.length === 0
                    ? _c("p", [
                        _vm._v(
                          "Er werden nog geen mails gestuurd naar " +
                            _vm._s(_vm.item.email) +
                            "."
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-card-actions", { staticClass: "mr-2" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }