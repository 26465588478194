var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-title", [_c("h3", [_vm._v("Verander status")])]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { staticClass: "pa-1", attrs: { md12: "" } }, [
                _vm.document
                  ? _c(
                      "div",
                      { staticClass: "my-2 d-flex justify-start" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { depressed: "", elevation: "2" },
                            on: { click: _vm.download }
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(" mdi-download ")
                            ]),
                            _vm._v(" Document ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "radio-tile-group" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: { color: "green", outlined: !_vm.status },
                        on: {
                          click: function($event) {
                            _vm.status = true
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-checkbox-marked-circle-outline")
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: { color: "red", outlined: _vm.status },
                        on: {
                          click: function($event) {
                            _vm.status = false
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-close-circle-outline")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "d-flex justify-end pb-4" },
        [
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.saveClicked } },
            [_vm._v("Controle Opslaan")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }